import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import slug from "limax";
import TransitionLink from "../components/TransitionLink";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import { Masonry } from "masonic";
import Img from "gatsby-image";

const GalleryPage = ({ location, pageContext }) => {
  const [maxColumnCount, setMaxColumnCount] = useState(3);

  const pageData = pageContext.node.data;
  const itemCount = pageData.images.length;

  const makeSlug = galleryItem => {
    return `${slug(galleryItem.title1 ? galleryItem.title1 : null)}-${
      galleryItem.image.localFile.id
    }`;
  };

  const MasonryCard = ({ data: item }) => (
    <div className="gallery-grid__item">
      <TransitionLink
        to={`/${pageContext.uid}/${makeSlug(item)}`}
        aria-label={item.title1}
      >
        <Img
          fluid={item.image.small}
          alt={item.image.alt || item.title1}
          className="bg-smoke"
        />
      </TransitionLink>
    </div>
  );

  const updateMaxColumnCount = () => {
    const width = window.innerWidth;
    if (width >= 1024) setMaxColumnCount(4);
    else if (width >= 768) setMaxColumnCount(3);
    else if (width >= 640) setMaxColumnCount(2);
    else setMaxColumnCount(1);
  };

  useEffect(() => {
    updateMaxColumnCount();
    window.addEventListener("resize", updateMaxColumnCount);
    return () => window.removeEventListener("resize", updateMaxColumnCount);
  }, []);

  return (
    <Layout
      pageTheme="light"
      backLink={"/"}
      pageColor={"white"}
      seoTitle={pageData.meta_title || pageData.title || "Gallery"}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Heading
        text={pageData.title}
        count={itemCount}
        className="mb-8 justify-center md:justify-start"
      />
      <div className="md:pb-10 -mb-5 md:mb-0">
        {pageData.images && (
          <Masonry
            items={pageData.images}
            columnGutter={20}
            rowGutter={0}
            maxColumnCount={maxColumnCount}
            overscanBy={5}
            render={MasonryCard}
          />
        )}
      </div>
    </Layout>
  );
};

GalleryPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default GalleryPage;
